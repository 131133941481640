@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
}

.contentContainer {
  composes: marketplaceModalRootStyles from global;
  min-height: calc(100vh - 60px);
  height: auto;

  & button {
    border: none;
  }

  align-items: center;

  @media (--viewportMedium) {
    height: unset;
    min-height: 100%;
  }

  @media (max-width: 991px) {
    padding: 0 30px !important;
  }

  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.content {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 29px;
  border-radius: 5px;
  overflow: hidden;
  flex-basis: auto;
  /* max-width: 965px; */
  max-width: 900px;

  @media (--viewportMedium) {
    margin-top: 7.5vh;
    margin-bottom: 7.5vh;
    min-height: 664px;
    padding: 0px !important;
    /* min-width: 1000px; */

    border-bottom: none;
  }

  @media (max-width: 991px) {
    min-height: 530px;
  }

  @media (max-width: 767px) {
    min-height: 100vh;
    border-radius: inherit;
    padding: 29px 15px 15px;
    max-width: 100%;
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.signupForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.privacyWrapper,
.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-bottom: 10px !important;
  /* font-family: 'Factor A Medium' !important; */
  font-weight: 500 !important;
  /* font-weight: 400 !important; */
  font-size: 20px !important;
  line-height: 21.99px !important;
  color: #000 !important;
  margin-top: 40px;
  font-family: var(--fontFamilyFactor);

  & > span {
    font-weight: 500;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  /* font-family: 'Factor A Medium' !important; */
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: var(--fontFamilyFactor);
  line-height: 18px;
  color: #000 !important;

  & > span {
    & > span {
      font-weight: 500 !important;
    }
  }
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 6px;
  }
}

.confirmInfoText {
}

.buttonIcon {
  /* position: absolute;
  left: 0;
  margin-left: 20px; */
  & svg {
    width: 20px;
    height: 20px;
  }
}

.socialButtonWrapper {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }

  margin-top: 60px;

  & button {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
    gap: 15px;
    /* font-family: 'Factor A Medium' !important; */
    /* font-weight: 400 !important; */
    /* font-size: 20px !important; */
    background-color: #f9f9f9 !important;
    padding: 10px 0 !important;
    border-radius: 10px !important;
    border: none !important;

    & > span:nth-child(2) {
      font-family: var(--fontFamilyHelvetica);
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.socialButtons {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;

  & > div {
    & > button {
      & > span:nth-child(1) {
        /* & > svg{
          width:16px;
          height:20px;
        } */
      }

      & > span:nth-child(2) {
        font-family: var(--fontFamilyHelvetica);
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.socialButtonFa {
  max-width: 45%;
  flex: 0 0 45%;

  & button {
    display: flex !important;
    justify-content: center !important;
    align-items: baseline !important;
    gap: 15px;
    /* font-family: 'Factor A Medium' !important;
    font-weight: 400 !important;
    font-size: 20px !important; */
    background-color: #f9f9f9 !important;
    padding: 10px 20px !important;
    border-radius: 10px !important;
    border: none !important;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--colorWhite);

  /* &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  } */
}

@media (--viewportMedium) {
  .socialButtonsOr {
    /* height: 34px; */
    margin: 15px 0;
  }
}

.socialButtonsOr {
  margin: 30px 0 0;
}

.socialButtonsOrText {
  background-color: var(--colorWhite);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.joinOrSignParent {
  display: flex;
  min-height: 664px;
  background-color: #ffffff;
  position: relative;

  @media (max-width: 991px) {
    min-height: 530px;
  }

  @media (max-width: 767px) {
    min-height: 100vh;
  }
}

.joinOrSignImage {
  /* background-color: red; */
  /* width: 50%;
  flex: 0 0 50%; */
  width: 48%;
  flex: 48%;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.joinOrSignAuthentication,
.traineeContainer {
  width: 50%;
  flex: 50%;
  padding: 30px;
  position: relative;

  /* width:50%;
  flex:0 0 50%;
  padding:30px; */
  @media (max-width: 991px) {
    padding: 25px 15px;
  }

  @media (max-width: 767px) {
    padding: 0px;
  }

  /* display:flex;
  justify-content: center;
  align-items: center; */
}

.joinNowContent {
  margin-top: 30px;
  /* min-height: 378px; */
  /* margin-bottom: 120px; */
  min-height: 580px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
    min-height: 400px;
  }

  @media (max-width: 767px) {
    min-height: 85vh;
    margin-bottom: 20px;
  }
}

.signinHeader {
  /* margin-bottom: 10px; */
  /* font-family: 'Factor A'; */
  /* font-weight:1000; */
  font-family: var(--fontFamilyFactor);
  font-weight: 500;
  color: #000;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    display: none;
  }
}

.emailBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  font-family: 'Factor A Medium';
  font-weight: 400;
  font-size: 20px;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  border: none !important;

  & svg {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 767px) {
    margin-top: 27px;
  }

  &:hover {
    box-shadow: var(--boxShadowButton);
    background-color: #f9f9f9;
  }

  & > span {
    font-family: var(--fontFamilyHelvetica);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  & > span {
    font-family: var(--fontFamilyHelvetica);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
}

.signinOrLogin {
  /* font-family: 'Factor A'; */
  font-family: var(--fontFamilyFactor);
  font-weight: 500;
  font-size: 14px;
  color: #000;

  & a {
    color: #000 !important;
    text-decoration: underline !important;
    margin-left: 5px;
    text-underline-offset: 0.2em;
  }
}

.close {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #676767;
  /* width: max-content; */
}

.back {
  /* font-family:var(--fontFamilyFactor); */
  font-family: var(--fontFamilyHelvetica);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  cursor: pointer;
}

.footer {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 10px;
  /* margin-bottom: 12px; */
  /* margin-top: 48px; */
  line-height: 14px;
  color: #676767;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  max-width: 420px;

  /* margin-top: 200px; */
  /* @media(max-width:767px){
    margin-top:170px;
  } */
  @media (max-width: 767px) {
    max-width: unset;
  }
}

.totalContent {
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between;
  height: 100%; */
}

.continueBtn {
  width: 100%;
  border: none;
  background-color: #000;
  color: #fff;
  margin-top: 25px;
  border-radius: 10px;
  padding: 15px 18px;
  cursor: pointer;

  &:hover {
    /* color: #000 !important; */
    color:#fff !important;
    box-shadow: var(--boxShadowButton);
  }
}

.fleggsImg {
  width: 80px;
  height: 64px;
}

.infoDiv {
  width: 50%;
  margin-bottom: 40px;

  & > p {
    margin: 10px 0 0 0;
  }
}

.userTypeDiv {
  display: flex;
  justify-content: space-between;
  /* height: 100%; */
}

.continueBtn {
  width: 100%;
  border: none;
  background-color: #000;
  color: #fff;
  margin-top: 25px;
  border-radius: 10px;
  padding: 15px 18px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: var(--fontFamilyHelvetica);

  &:hover {
    color: #fff !important;
    box-shadow: var(--boxShadowButton);
  }
}

.fleggsImg {
  height: 80px;
  width: 80px;
}

.infoDiv {
  width: 50%;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.userTypeDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: 48%;
    /* flex-basis: 48%; */
    flex: 0 0 48%;
    min-height: 344px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--fontFamilyFactor);
    color: #000000;
    cursor: pointer;
    margin-bottom: 60px;

    &:hover {
      border: 2px solid #4f4f4f !important;
    }

    @media (max-width: 767px) {
      width: 100%;
      flex: 0 0 100%;
      /* font-size: 18px; */
      margin-bottom: 15px;
      min-height: 300px;
    }
  }

  & > p {
    color: #000;
    width: 50%;
    font-family: var(--fontFamilyFactor);
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 767px) {
      width: 100%;
      margin: 20px 0 30px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .selectedUser {
    border: 2px solid #4f4f4f !important;
  }
}
.userTypeDiv > div:nth-child(1) {
  background-color: #1ca655 !important;
}
.userTypeDiv > div:nth-child(2) {
  background-color: #ffbfd6 !important;
}

.bottomDiv {
  display: flex;

  & > button {
    cursor: pointer;
    color: #676767;
    border: none;
    font-family: var(--fontFamilyFactor);
    font-size: 14px;
    font-weight: 500;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:last-child {
      margin-left: auto;
      color: #000;
      font-weight: 500;
      margin-bottom: 45px;
    }
  }
}

.traineeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > img {
    width: 69px;
    height: auto;
  }
}

.exploreBtn {
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 100px;
  font-family: var(--fontFamilyHelvetica);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 30px;
  height: 60px;

  @media (max-width: 767px) {
    font-size: 15px;
    padding: 5px 25px;
    height: 50px;
  }
}

.topRow {
  display: flex;
  align-items: center;

  & > button {
    margin-left: auto;
    font-size: 14px;
    cursor: pointer;
    line-height: 17px;
    font-weight: 400;
    font-family: var(--fontFamilyHelvetica);
  }
}

.credentaiDiv {
  width: auto;
  flex-basis: 48%;
  height: 344px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--fontFamilyFactor);
  color: #000000;
  cursor: pointer;
  margin-bottom: 60px;
  border: 0.5px solid #4f4f4f;

  &:hover {
    border: 0.5px solid #4f4f4f;
  }

  & > p {
    margin-top: 0;
  }

  & > div {
    & > div {
      & > label {
        text-align: center;

        & > span {
          font-size: 20px !important;
          line-height: 22px !important;
          font-weight: 500 !important;
          font-family: var(--fontFamilyFactor) !important;
          /* padding-bottom:10px; */
        }

        & > p {
          font-size: 12px;
          line-height: 14px;
          color: #000;
          font-family: var(--fontFamilyFactor);
          margin: 10px 0 !important;
          font-weight: 400;
        }
      }
    }
  }
}

.uploadDocInput {
  display: none;
}

.signupformwrapper {
}

.signupformMain {
}

.laterDiv {
  margin-top: auto;
}

.VerificationMain {
  /* align-items:center; */
  /* min-height:auto; */
  & > div {
    width: 100%;
    flex: 0 0 100%;
  }
}

.middleInfo {
  min-height: 664px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > img {
    width: 69px;
    height: auto;
  }

  @media (max-width: 767px) {
    min-height: 100vh;
    justify-content: flex-start;
  }
}

.changeWrap {
  display: block;

  & > div {
    width: 100%;
    flex: 0 0 100%;

    & > a {
      & > img {
        width: 69px;
        height: auto;
      }
    }
  }
}

.authPageWrap {
  & > div {
    & > div {
      & > header {
        & > div {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }
}

.EmailVerifyWrap {
  & > h1 {
    margin-top: 0;
  }

  & > div {
    & > div:nth-child(2) {
      bottom: 26px;
      right: 26px;
      left: 26px;

      @media (max-width: 991px) {
        right: 15px;
        left: 15px;
      }

      @media (max-width: 767px) {
        max-width: unset;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }
}

.uploadBtn {
  width: 241px;
  border-radius: 50px;
  padding: 15px;
  cursor: pointer;
  background-color: #000;
  border: 0;
  color: #ffffff;
  font-size: 14px;

  &:disabled {
    background-color: var(--colorGrey100) !important;
    cursor: not-allowed;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    width: 100% !important;
  }
  @media (max-width: 991px) {
    width: 200px;
  }
}

.uploadDiv {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: 767px) {
    padding: 0px 20px;
    flex-direction: column-reverse;
    margin-top: 100px;
    gap: 0px;
  }
}
.inlinetxtmgw {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}
.pdfDiv {
  border: 1px solid;
  max-width: max-content;
  padding: 5px 10px;
  margin: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & a {
    color: #000000 !important;
  }

  & button {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    /* text-align: center; */
    border: 0;
    background-color: #d0cdcd;
    color: white;
    /* padding:1px 8px; */
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #a2a0a0;
      /* color: #000000; */
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #000000;
  padding: 20px 30px !important;
  border-radius: 100px !important;
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 12px;
  line-height: 14.65px;

  &:hover {
    background-color: #000000;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.submitButton1 {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #000000;
  padding: 20px 30px !important;
  border-radius: 100px !important;
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 12px;
  line-height: 14.65px;
  /* &:hover{
    background-color: #000000;
  } */

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    /* margin-top: 100px; */
  }
}

.goBack {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 24px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #000000;
  padding: 15px 30px !important;
  border-radius: 100px !important;
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 14px;

  /* line-height:14.65px; */
  /* margin-right:10px; */
  &:hover {
    background-color: #000000;
    color: #fff;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media (max-width: 767px) {
    width: 100% !important;
    margin-top: 0px;
  }
}

.banner {
  position: unset;
}

.signupError {
  margin-top: 10px !important;
}

.modalClassName {
  top: 129px;

  @media (max-width: 767px) {
    top: 110px;
  }
}

.MobsigninHeader {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.loginHeader {
  display: block;
}

.signinOrLoginHead {
  display: block;
}

.joinOrSignParentNew {
  & > div {
    & > div:last-child {
      max-width: 700px;
      padding: 30px;
      display: none;

      @media (max-width: 767px) {
        display: block;
        position: static;
        padding: 0;
      }
    }
  }
}
